@import '../../../base/base';

.widget-content-area {
  box-shadow: none !important;
  border: none !important;
  border-radius: 6px;
}

.toggle-list {
  margin: 21px 21px 0 21px;
}

/* HTML 5 */

.dt-buttons .dt-button {
  color: #fff !important;
  background-color: #4361ee !important;
  border-color: $primary;
  margin-bottom: 5px;
  margin-right: 5px;
}

/* Multiple Tables */

#DataTables_Table_1_wrapper {
  margin-top: 70px;
}